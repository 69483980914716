import React from "react"
import { isAndroid, isIOS } from "react-device-detect"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import BasicPage from "../templates/BasicPage"
import Download from "../components/Download"

const DownloadPage = (): JSX.Element => {
  if (isAndroid && window) {
    window.location.href =
      "market://details?id=com.geniedelivery.consumerapplication"
  }
  if (isIOS && window) {
    window.location.href = "https://apps.apple.com/app/id1533642126"
  }
  return (
    <Layout>
      <SEO
        title="Download"
        description="Genie is available on Android and iOS. Choose your phone type and download our app!"
      />
      <BasicPage>
        <Download />
      </BasicPage>
    </Layout>
  )
}

export default DownloadPage
